
<div class="con" >

  <div style="width: calc(90% - 50px);" class="k">
    <h1>
      Кто может стать членом КРО ОО РосГео?
    </h1>
    Членом Общества может стать любой человек в возрасте от 18 лет. Мы будем рады приветствовать в своих рядах ученых, инженеров, техников, специалистов различного профиля, занятых геологическим изучением и использованием недр, преподавателей и учащихся высших и средних учебных заведений геологического  профиля, специалистов других отраслей, чьи профессиональные и творческие интересы связаны с развитием отечественной геологии. Кроме того, к вступлению приглашаются люди, не связанные с геологией в своей профессиональной деятельности, однако интересующиеся изучением недр Земли.
    <h1>Как вступить в общество?</h1>
    1. Скачайте, заполните заявление и отправьте скан-копию на адрес rosgeo39@mail.ru<br><br>
    <a href="https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/docs%2Fзаявление%20на%20вступление(1).doc?alt=media&token=f52aa185-7a55-4038-b71b-a3be2f2219d9"><button>Скачать</button></a><br><br>
    2. Заполните согласие на обработку персональных данных<br><br>
    <a href="https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/docs%2Fсогласие_на_размещение_информации_на_сайте_КРО_ОО_РосГео.rtf?alt=media&token=f6dd212c-e246-4b47-8fd8-7bf02b7a1e14"><button>Скачать</button></a><br><br>
    3. Прочтите инструкцию об оплтае и уплатите членский взнос в размере 900 рублей (для неработающих пенсионеров – 240 рублей)<br><br>
    <a href="https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/docs%2Fинструкция%20по%20оплате(1).docx?alt=media&token=02fd4778-4d4f-4bd2-97a0-39e85da3995f"><button>Скачать</button></a><br><br>

    4.	Отправьте подтверждение оплаты членского взноса на адрес rosgeo39@mail.ru<br><br>
    <b>Оплата членских взносов осуществляется ежегодно в безналичном порядке посредством системы онлайн-банка или посещения банка и оплаты по квитанции.</b>
    <br>
    <br>
    _____<br>
    Членство в КРО ОО РосГео предусматривает ежегодную уплату членских взносов в соответствии со статьёй 31 Федерального закона от 19.05.1995 N 82-ФЗ "Об общественных объединениях", пунктом 4.10 Устава КРО ОО РосГео и на основании решения Правления КРО ОО РосГео (протокол от 05.04.2019).
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-young-g',
  templateUrl: './young-g.component.html',
  styleUrls: ['./young-g.component.css']
})
export class YoungGComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="con" >
  <div>
    <h1>Реквизиты</h1>
    <div class="z">
      <p>
        р/с 40703810638310100563 в ПАО «Сбербанк России», г. Москва<br>
        БИК 044525225<br>
        ИНН 7725043597<br>
        к/с 3010181040000000225<br>
        <br><br>
        КРО ОО РосГео<br>
        <br>
        Калининградское региональное отделение общественной организации «Российское геологическое общество»<br>
        <br>
        Kaliningrad regional branch of the public organization "Russian Geological Society"<br>

      </p>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  o = false;
  otitle = '';
  otext = '';
  oimg = '';

  members1 = [
    {name: 'Колотов Олег Сергеевич', pos: 'Председатель', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FПредседатель_фото.jpg?alt=media&token=3f192ad1-087d-4c81-a74f-73039e799a93', text: 'В 2009 году с отличием окончил бакалавриат кафедры сейсмометрии и<br>' +
        'геоакустики отделения геофизики геологического факультета МГУ имени М.<br>' +
        'В. Ломоносова, в 2011 году с отличием окончил магистратуру по программе<br>' +
        '«Геолого-геофизические исследования нефтяных и газовых месторождений»<br>' +
        'этой же кафедры. С 2019 года – аспирант АО ИО РАН в Калининграде.<br>' +
        'Производственная деятельность связана, как с твердыми полезными<br>' +
        'ископаемыми (калийно-магниевые соли), так и поиском месторождений<br>' +
        'углеводородного сырья на территории Калининградской области. С декабря<br>' +
        '2020 года возглавляет камеральную геофизическую партию в составе АО<br>' +
        '«Калининградгеофзика».<br>' +
        'С февраля 2020 года – ведущий инженер лаборатории геологии<br>' +
        'Атлантики АО ИО РАН.<br>' +
        'В феврале 2021 года избран председателем Калининградского<br>' +
        'регионального отделения общественной организации «Российское<br>' +
        'геологическое сообщество».'},
    {name: 'Бубнова Екатерина Сергеевна', pos: 'Ученый секретарь', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2Fученый%20секретарь_фото.jpg?alt=media&token=008ea28a-08a2-435b-8021-e81c5b0fd4e8', text: 'Образование:<br>' +
        'Балтийский федеральный университет им. И. Канта, 2013 Специальность «Геоэкология»<br>' +
        'Балтийский федеральный университет им. И. Канта, 2015 Магистратура, направление «География»,<br>' +
        'профиль «Прибрежная география»<br>' +
        'Балтийский федеральный университет им. И. Канта, 2018 Аспирантура, 05.06.01 «Науки о земле»,<br>' +
        'направление «Океанология»<br>' +
        'Научная степень: Кандидат географических наук, специальность 25.00.36 «Геоэкология». Тема<br>' +
        'диссертации: «Роль взвешенного вещества в изменчивости геоэкологического состояния юго-<br>' +
        'восточной части Балтийского моря».<br>' +
        'Научные интересы – морская геология, седиментология, гидрохимия, геоэкология, морское<br>' +
        'природопользование.<br>' +
        'Список научных публикаций:<br>' +
        'Alexandrov S.V., Krek A.V., Bubnova E.S., Danchenkov A.R. Eutrophication and effects of algal bloom in<br>' +
        'the Curonian Lagoon alongside the south-western part of the Curonian Spit (Russia) // Baltica. 2018 Vol. 1<br>' +
        'Bukanova T., Kopelevich O., Vazyulya S., Bubnova E., Sahling I. Suspended matter distribution in the south-<br>' +
        'eastern Baltic Sea from satellite and in situ data // International Journal of Remote Sensing. 2018 Vol. 39<br>' +
        'Issue 24: Remote Sensing of the Coastal Zone of the European Seas. P. 9317-9338<br>' +
        'Лукашин В.Н., Кречик В.А., Бубнова Е.С., Стародымова Д.П., Клювиткин А.А. Взвесь в Балтийском<br>' +
        'море: распределение и химический состав // Океанологические исследования. 2018 Т. 46 № 2 С. 145-<br>' +
        '166<br>' +
        'Бубнова Е.С., Капустина М.В., Кречик В.А., Сивков В.В. Распределение взвеси в поверхностном слое<br>' +
        'восточно-экваториальной Атлантики // Океанология. 2020 Т. 60 № 2 С. 259–267. doi:<br>' +
        '10.31857/S0030157420010049<br>' +
        'Krechik V., Krek A., Bubnova E., Kapustina M. Mixing zones within the complex transitional waters of the<br>' +
        'Baltic Sea Vistula Lagoon // Regional Studies in Marine Science. 2020 V.34. 101023<br>' +
        'doi:10.1016/j.rsma.2019.101023<br>' +
        'Ryabchuk D., Sergeev A., Zhamoida V., Budanov L., Krek A., Neevin I., Bubnova E., Danchenkov A.&<br>' +
        'Kovaleva O. High resolution geological mapping – towards understanding of postglacial development and<br>' +
        'Holocene sedimentation processes in the eastern Gulf of Finland (EMODnet-geology case study) // Geological<br>' +
        'Society, London, Special Publication. 2020 doi: 10.1144/SP505-2019-127<br>' +
        'Sivkov V., Bubnova E. Temporal and spatial variability of the suspended particulate matter in the Gdansk<br>' +
        'Deep and Eastern Gotland Basin // Baltica. 2020 V. 33 № 1 P. 35–45. doi:10.5200/baltica.2020.1.4'}
  ];
  members2 = [
    {name: 'Баширова Лейла Джангировна', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FБаширова_фото.jpg?alt=media&token=0d5a42c0-bf60-4fba-82aa-0dc1fb4df853', text: 'С 2010 года – член КРО ОО РосГео, с 2016 года – член правления КРО ОО РосГео<br>' +
        'Место работы:<br>' +
        '1 Заместитель директора АО ИО РАН по научной работе, заведующая лабораторией<br>' +
        'геологии Атлантики АО ИО РАН.<br>' +
        '2.Доцент кафедры географии океана БФУ им. И. Канта.<br>' +
        'Выпускница факультета биоэкологии БФУ им. И. Канта (РГУ им. И. Канта, 2008),<br>' +
        'специальность – биоэколог.<br>' +
        'В 2014 году защитила кандидатскую диссертацию на тему «Миграции Северного<br>' +
        'полярного фронта и Северо-Атлантического течения на протяжении последних 190000 лет<br>' +
        'по данным изучения планктонных фораминифер» по специальности 25.00.28 –<br>' +
        'Океанология (ИО РАН, г. Москва).<br>' +
        'Научный руководитель: Лукашина Н.П., дгмн, доцент, внс лаб. геоэкологии АО ИО РАН.<br>' +
        'Научные интересы: морская геология, палеоокеанология и биостратиграфия,<br>' +
        'палеоклиматология, морское культурное наследие.<br>' +
        'Участие в грантах и программах:<br>' +
        '- РФФИ №11-05-16058-моб_з_рос «Участие в VI Международной конференции<br>' +
        '«Микропалеонтология, микробиология, мейобентология и окружающая среда»»<br>' +
        '(руководитель);<br>' +
        '- РФФИ №12-05-90818-мол_рф_нр «Исследование особенностей последнего ледниково-<br>' +
        'межледникового перехода по данным палеоокеанологических реконструкций в северной<br>' +
        'части Западно-Европейской котловины» (руководитель);<br>' +
        '- РФФИ №12-05-00240-а «Реконструкция поверхностной и глубинной океанической<br>' +
        'циркуляции в средних широтах Северной Атлантики на протяжении двух последних<br>' +
        'ледниковых-межледниковых циклов» (исполнитель);<br>' +
        '- РФФИ №13-05-90704-мол_рф_нр «Исследование региональных особенностей Северо-<br>' +
        'Атлантического течения в среднем плейстоцене-голоцене по данным фораминиферового<br>' +
        'анализа» (руководитель);<br>' +
        '- РФФИ №14-05-31349-мол_а «Изменения поверхностной и глубинной палеоциркуляции в<br>' +
        'Северной Атлантике во время событий Хайнриха 1-11» (руководитель);<br>' +
        '- РФФИ №14-35-50027-мол_нр «Исследование изменений поверхностной циркуляции в<br>' +
        'Северной Атлантике (дрифт Фени) в позднем плейстоцене-голоцене по данным<br>' +
        'микропалеонтологического анализа» (исполнитель);<br>' +
        '- РНФ № 14-37-00047 «Геоэкологические условия морского природопользования в<br>' +
        'российском секторе Юго-Восточной Балтики» (исполнитель);<br>' +
        '- РНФ № 14-50-00095 «Мировой океан в ХХI веке: климат, экосистемы, ресурсы,<br>' +
        'катастрофы», направление: Взаимодействие геосфер и минеральные ресурсы Мирового<br>' +
        'океана (исполнитель);<br>' +
        '- РФФИ № 15-35-50446-мол_нр «Исследование миграций Северо-Атлантического течения<br>' +
        'во время последней дегляциации по данным изучения планктонных фораминифер»<br>' +
        '(исполнитель);'},
    {name: 'Десятков Владимир Матвеевич', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FДесяттков_фото.jpg?alt=media&token=867bcebe-5a98-4dc9-86f7-bcf9b514a6cc', text: 'Родился 29.08.1947 г. Закончил Геологический факультет Московского<br>' +
        'государственного университета им. М.В.Ломоносова в 1970 г. Кандидат<br>' +
        'геолого-минералогических наук с 1980 г. Регионы производственной<br>' +
        'деятельности: Московская синеклиза, Балтийская синеклиза, Тимано-<br>' +
        'Печорская провинция, острова Северного ледовитого океана (Баренцево и<br>' +
        'Карское моря). Трудовой стаж 50 лет.<br>' +
        'Имеет награды:<br>' +
        '-нагрудный знак «Отличник разведки недр»<br>' +
        '-нагрудный знак «Первооткрыватель месторождения»<br>' +
        '-нагрудный знак «Почетный разведчик недр»<br>' +
        '-знак «300 лет горно-геологической службе России»<br>' +
        '-знак «50 лет Дня Геолога»<br>' +
        'Работал в организациях: трест «Ярославнефтегазразведка», трест<br>' +
        '«Арктикморнефтегазразведка», ГП «Архангельскгеолдобыча», ПАО<br>' +
        '«ЛУКОЙЛ».<br>' +
        'В настоящее время заместитель руководителя – начальник отдела<br>' +
        'территориальных геологических фондов Калининградского филиала ФБУ<br>' +
        '«Территориальный фонд геологической информации по Северо-Западному<br>' +
        'федеральному округу».'},
    {name: 'Краснов Евгений Васильевич', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FКраснов_фото.jpg?alt=media&token=cf2d165f-1fcc-4c3f-9e99-0d7f5112b81c', text: ''},
    {name: 'Мельник Роман Валерьевич', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FМельник_фото.jpg?alt=media&token=eac52bb7-47d7-4f63-a952-2267d8a51dd0', text: 'Выпускник 2003 года Южно-Российского Государственного Технического Университета<br>' +
        '(ЮРГТУ (НПИ)) г. Новочеркасск, по специальности - "Геология и разведка<br>' +
        'месторождений полезных ископаемых".'},
    {name: 'Мычко Эдуард Вагифович', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FМычко_фото.jpg?alt=media&token=194c7151-615a-43ad-8a7a-fe5fd850fb00', text: 'В 2009 году окончил бакалавриат кафедры палеонтологии геологического ф-та МГУ имени М.В.<br>' +
        'Ломоносова, в 2011 – магистратуру этой же кафедры, 2014 – аспирантуру. В апреле 2017 года защитил<br>' +
        'кандидатскую диссертацию на тему «Трилобиты среднего-верхнего карбона и перми Северной<br>' +
        'Евразии» по специальности 25.00.02 – «палеонтология и стратиграфия» (место защиты – МГУ имени<br>' +
        'М.В. Ломоносова). Руководитель диссертационной работы д.г-м.н, профессор Алексеев А.С. Автор и<br>' +
        'соавтор более 25 научных статей, опубликованных в рецензируемых журналах и в научных сборниках.<br>' +
        'С января 2018 года сотрудник АО ИО РАН в Калининграде. Лично и в соавторстве с другими<br>' +
        'исследователями описал 22 различных новых вида и рода ископаемых животных, в частности –<br>' +
        'позднепалеозойских трилобитов и циклид, а также мезозойских крабов. Статьи с этими описаниями<br>' +
        'опубликованы в международных научных рецензируемых журналах.<br>' +
        'Научные интересы: трилобиты каменноугольной и пермской системы, их систематика, эволюция и<br>' +
        'стратиграфическое распространение; ракообразные и другие членистоногие позднего палеозоя;<br>' +
        'палеогеновая система Прибалтики; компьютерные технологии в палеонтологии.<br>' +
        'Основные публикации за 2012-2021 гг.:<br>' +
        'B.W.M. van Bakel, Mychko E.V., Spiridonov A., Jagt J.W.M., Fraaije R.H.B. New Cretaceous crabs<br>' +
        '(Crustacea, Brachyura) from Moscow Oblast andDagestan (Russia): patterns in phylogeny and morphospace<br>' +
        'of theoldest eubrachyurans (Dorippoidea) // Cretaceous Research, 2021, Vol. 119 104675 DOI:<br>' +
        '10.1016/j.cretres.2020.104675<br>' +
        'Мычко Э.В. Янтарный край: древнее сокровище в янтаре // Природа, 2020 № 8 C. 22–38.<br>' +
        'Carrie E. Schweitzer, Eduard V. Mychko & Rodney M. Feldmann. Revision of Cyclida (Pancrustacea,<br>' +
        'Multicrustacea), with five new genera // Neues Jahrbuch für Geologie und Paläontologie – Abhandlungen,<br>' +
        '2020, Vol. 296, iss. 3, pp. 245–303 DOI:10.1127/njgpa/2020/0905<br>' +
        'Мычко Э.В., Тарасенко К.К. Первая находка базилозаврид (Mammalia, Cetacea) в верхнеэоценовых<br>' +
        'отложениях Прибалтики (Калининградская область) // Палеонтологический журнал, 2020, № 3, с. 103–<br>' +
        '110 (Eduard V. Mychko, Konstantin K. Tarasenko (2019) The first finding of Basilosauridae (Mammalia:<br>' +
        'Cetacea) in Upper Eocene of Baltic States (Russia, Kaliningrad region). Paleontological Journal, Vol. 54, No.<br>' +
        '3 P. 311-318. DOI: 10.1134/S0031030120030119)<br>' +
        'Мычко Э.В., Мазаев А.В., Давыдов А.Э., Гибшман Н.Б., Сахненко К.В., Алексеев А.С. Аккермановка-<br>' +
        'Хабарное – новый раннекаменноугольный лагерштетт на территории России (Южный Урал) // Бюл.<br>' +
        'Моск. О-ва Испытателей Природы. Отд. Геол. 2019 Т. 94, Вып. 3 С. 46–55.<br>' +
        'Eduard V. Mychko, Rodney M. Feldmann, Carrie E. Schweitzer & Alexander S. Alekseev. New genus of<br>' +
        'Cyclida (Crustacea) from Lower Carboniferous (Mississippian, Viséan) of Russia and England and new<br>' +
        'species from Viséan of Russia // Neues Jahrbuch für Geologie und Paläontologie - Abhandlungen, 2019, Vol.<br>' +
        '294, iss. 1, pp. 81-90 DOI:10.1127/njgpa/2019/084<br>' +
        'Мычко Э.В., Дернов В.С. Новые находки трилобитов в среднем карбоне Донецкого бассейна // Бюл.<br>' +
        'Моск. О-ва Испытателей Природы. Отд. Геол. 2019 Т. 94, Вып. 1, с. 52–63.<br>' +
        'Valery V. Chernykh, Galina V. Kotlyar, Boris I.Chuvashov, Ruslan V. Kutygin, Tatiana V. Filimonova,<br>' +
        'GuzalM. Sungatullina, Gunar A. Mizens, Rafael Kh. Sungatullin,Tatiana N. Isakova, Maxim S. Boiko,<br>' +
        'Alexander O. Ivanov,Nuriia G. Nurgalieva, Yury P. Balabanov, Eduard V. Mychko, Bulat I. Gareev, Georgii<br>' +
        'A. Batalin. Multidisciplinary study of the Mechetlino Quarrysection (Southern Urals, Russia) — the GSSP'},
    {name: 'Панов Валерий Дмитриевич', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FПанов_фото.jpg?alt=media&token=1169d47e-eb52-43e5-9185-f04c3a63b234', text: ''},
    {name: 'Сивков Вадим Валерьевич', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FСивков_фото.jpg?alt=media&token=3aa2c2c5-6f5c-4f84-8fc2-1fca4ba42af4', text: 'В 1979 году окончил с отличием географический факультет Калининградского государственного университета (ныне БФУ им. И. Канта) (специальность географ). После университета работал инженером-океанологом в военной гидрографии Балтийского флота. <br>' +
        '<br>' +
        'После службы в Советской армии в 1981 году принят на работу в АО ИО РАН на должность инженера лаборатории геологии Атлантики. В 1986 году окончил аспирантуру по специальности «Геология океанов и морей». В 1994 году защитил кандидатскую диссертацию на тему «Влияние течений на концентрацию и дисперсность осадочного вещества (на примере характерных районов Северной Атлантики)». В 2006 году инициировал создание лаборатории геоэкологии, которую возглавлял до 2015 года. С 2009 года и по настоящее время – директор АО ИО РАН. <br>' +
        '<br>' +
        'Специалист в области седиментологии, литодинамики, геоморфологии, палеоокеанологии (реконструкция интенсивности придонных течений). Специализировался на изучении взвешенного осадочного вещества в морях и океанах, впервые внедрив кондуктометрический метод изучения дисперсности в практику отечественных исследований. Сфера научных интересов также главным образом связана с изучением литодинамических процессов в Атлантическом океане и Балтийском море. Особое внимание уделял роли придонных геострофических (контурных) течений в осадконакоплении и формировании особого типа осадков – контуритов. Один из основных разработчиков программы экологического мониторинга разработки первого в России морского нефтяного месторождения (Балтийское море, Кравцовское месторождение, D6), в реализации которого принимал участие с 2003 года по настоящее время. Занимался исследованиями в области геологии месторождений балтийского янтаря. <br>' +
        '<br>' +
        'Имеет многолетний опыт организации и руководства морскими экспедиционными исследованиями на научных судах в различных районах Атлантического океана и в Балтийском море. <br>' +
        '<br>' +
        'Популяризатор науки. <br>' +
        '<br>' +
        'С 2001 года ведет педагогическую деятельность. В 2014 году возглавлял базовую кафедру географии океана БФУ им. И. Канта, с 2016 по настоящее время является доцентом кафедры. Под руководством В.В. Сивкова успешно защитили кандидатские диссертации 6 соискателей. <br>' +
        '<br>' +
        '  <br>' +
        '<br>' +
        'Руководство научными проектами: <br>' +
        '<br>' +
        '2010 г. РФФИ №10-05-05049-б "Развитие МТБ для проведения исследований по области знаний 05 в Атлантическом отделении Института океанологии им. П.П. Ширшова РАН" <br>' +
        '<br>' +
        '2010. РФФИ №10-05-90738-моб_ст "Научная работа Сергеева Александра Юрьевича из Всероссийского научно-исследовательского геологического института им. А.П. Карпинского, г. Санкт-Петербург, в Атлантическом отделении Учреждения Российской Академии наук Институт океанологии им. П.П. Ширшова (АО ИО РАН), г. Калининград" <br>' +
        '<br>' +
        '2006-2008. Tacis-Interreg. № 2006/122-473. Перспективы развития морской ветроэнергетики в акваториях Литвы, Польши и России». <br>' +
        '<br>' +
        '2011-2012 гг. РФФИ №11-00-14243-ир "Получение доступа к научным информационным ресурсам зарубежных издательств" <br>' +
        '<br>' +
        '2011-2013 гг. РФФИ №11-05-01093-а "Структура и чувствительность субаквальных ландшафтов Балтийскго моря к природным и антропогенным воздействиям" <br>' +
        '<br>' +
        '2011 г. РФФИ №11-05-05051-б "Развитие МТБ для проведения исследований по области знаний 05 по ситуационному мониторингу и моделированию динамики вод и осадочного вещества в прибрежной и шельфовой зоне Балтийского моря" <br>' +
        '<br>' +
        '2012 г. РФФИ №12-05-90830-мол_рф_нр "Построение цифровой геоинформационной модели геологического строения верхнечетвертичного покрова Российской части Куршской косы и ее подводного берегового склона. Научный проект Сергеева Александра Юрьевича из Всероссийского научно-исследовательского геологического института им. А.П. Карпинского, Санкт-Петербург в Атлантическом отделении Института Океанологии им. П.П. Ширшова РАН, Калининград" <br>' +
        '<br>' +
        '2012-2014 гг. Проект по программе НАТО «Наука во имя мира и безопасности». «Разработка решений в целях эффективного управления нефтяными разливами в Юго-Восточной Балтике» (соруководитель проекта). <br>' +
        '<br>' +
        '2015-2017 гг. Программа Президиума РАН. «Сравнительный анализ последней дегляциации в северном и южном полушариях на примере Скандинавского и Патагонского ледниковых щитов». <br>' +
        '<br>' +
        '2019-2021 гг. РНФ №19-17-00246 «Антарктическая донная вода в северо-восточной Атлантике: седиментационный эффект и ледниковая цикличность». <br>' +
        '<br>' +
        '2017-2020 гг. Проект BalticRIM: Комплексное управление морским культурным наследием региона Балтийского моря (BalticRIM: Baltic Sea Region Integrated Maritime Cultural Heritage Management). <br>' +
        '<br>' +
        'Участие в проектах: <br>' +
        '<br>' +
        '2014-2016 гг. РНФ № 14-37-00047 «Геоэкологические условия морского природопользования в российском секторе Юго-Восточной Балтики». <br>' +
        '<br>' +
        '2018-2021 гг. РНФ №18-77-10016 «Корреляция ключевых климатических событий голоцена в Северной Атлантике и Балтике». <br>' +
        '<br>' +
        '2014-2019 гг. РНФ № 14-50-00095 «Мировой океан в ХХI веке: климат, экосистемы, ресурсы, катастрофы», направление: Взаимодействие геосфер и минеральные ресурсы Мирового океана (исполнитель). <br>' +
        '<br>' +
        'Награды <br>' +
        '<br>' +
        'Министерство природных ресурсов РФ, 2000 г. <br>' +
        '<br>' +
        'Памятный знак "300 лет горно-геологической службе России". <br>' +
        '<br>' +
        'Медаль РФ "300 лет российскому флоту" <br>' +
        '<br>' +
        'Диплом Российского геологического общества, 2011 г. «За многолетний добросовестный труд и большой вклад в развитие минерально-сырьевой базы России» с вручением нагрудного знака "Геологическая служба России". <br>' +
        '<br>' +
        'Почетная грамота за большой вклад в развитие океанологии и в связи с 60-летием АО ИО РАН. <br>' +
        '<br>' +
        'Научные общества <br>' +
        '<br>' +
        'Председатель (2000-2006), член правления (с 2006 года по настоящее время) Калининградского отделения Российского геологического общества. <br>' +
        '<br>' +
        'Член научно-технического совета ФГБУ "Национальный парк "Куршская коса". <br>' +
        '<br>' +
        'Сопредседатель научно-экспертного Совета по вопросам берегозащиты при Министерстве строительства Калининградской области. <br>' +
        '<br>' +
        'Постоянный член оргкомитета Международной научной конференции (школы) по морской геологии "Геология морей и океанов". <br>' +
        '<br>' +
        'Член редколлегии журнала Baltica (Литва). <br>' +
        '<br>' +
        'Член правления Калининградского отделения Русского географического общества. <br>' +
        '<br>' +
        'Основные научные труды: <br>' +
        '<br>' +
        'Монографии и главы в монографиях <br>' +
        '<br>' +
        'Сивков В.В., Каджоян Ю.С., Пичужкина О.Е., Фельдман В.Н. Нефть и окружающая среда Калининградской области // под ред. В. В. Сивкова (ответственный редактор), Ю. С. Каджояна, О. Е. Пичужкиной, В. Н. Фельдмана. Калининград, 2012. Том 2. Море. <br>' +
        '<br>' +
        'Sivkov V., Dorokhov D., Ulyanova M. Submerged holocene wave-cut cliffs in the South-eastern part of the Baltic Sea: reinterpretation based on recent bathymetrical data // J. Harff et al. (eds.) The Baltic Sea Basin, Springer-Verlag Berlin Heidelberg. 2011. pp. 203-217. <br>' +
        '<br>' +
        'Spiridonov M., Ryabchuk D., Zhamoida V., Sergeev A., Sivkov V., Boldyrev V. Geological Hazard Potential at the Baltic sea and Its Coastal Zone: Examples from the Eastern Gulf of Finland and the Kaliningrad Area // J. Harff et al. (eds.) The Baltic Sea Basin. Springer-Verlag Berlin Heidelberg. 2011. pp. 337-364. <br>' +
        '<br>' +
        'Terrestrial and Inland Water Environment of the Kaliningrad Region. Eds. Gritsenko, V.A., Sivkov, V.V., Yurov, A.V., Kostianoy, A.G. Springer, Berlin, Heidelberg, 2018. Pp. 550. <br>' +
        '<br>' +
        'Атласы <br>' +
        '<br>' +
        'Атлас геологических и эколого-геологических карт Российского сектора Балтийского моря / Гл. ред. О. В. Петров (Авторы: А. В. Амантов, М. Г. Амантова, Т. В. Бодряков, В. Л. Болдырев, А. Г. Григорьев, Д. В. Дорохов, В. А. Жамойда, В. А. Загородных, Ю. П. Кропачев, Т. А. Кунаева, С. М. Ликсущенков, С. Ф. Мануйлов, А. Ф. Морозов, Б. Н. Морозов, П. Е. Москаленко, Е. Н. Нестерова, О. В. Петров, Д. В. Рябчук, А. Ю. Сергеев, В. В. Сивков, Е. А. Синькова, М. А. Спиридонов, В. А. Шахвердов). СПб.: ВСЕГЕИ, 2010. 78 с. <br>' +
        '<br>' +
        'Статьи <br>' +
        '<br>' +
        'Баширова Л.Д., Дорохова Е.В., Сивков В.В. Литодинамические исследования в районе северо-западного атлантического срединноокеанического глубоководного канала // Океанология. 2019. Т. 59. № 5. С. 803–809. <br>' +
        '<br>' +
        'Баширова Л.Д., Дорохова Е.В., Сивков В.В., Андерсен Н., Кулешова Л.А., Матуль А.Г. Палеотечения в районе разлома Чарли-Гиббс в позднечетвертичное время // Океанология. 2017. Т. 57. № 3. С. 491-502. <br>' +
        '<br>' +
        'Буренков В.И., Купцов В.М., Сивков В.В., Шевченко В.П. Пространственное распределение и дисперстный состав взвеси в море Лаптевых в августе-сентябре 1991 г. // Океанология. 1997. 37 (6). С. 831-837. <br>' +
        '<br>' +
        'Свиридов Н.И., Сивков В.В., Использование сейсмоакустических данных для изучения придонных течений Западной Балтики // Океанология. 1992. № 5. С. 941-947 <br>' +
        '<br>' +
        'Свиридов Н.И., Сивков В.В., Руденко М.В., Тримонис Е.С. Геологические следы донных течений в Готландской впадине Балтийского моря // Океанология. 1997. Т. 37. № 6. С. 928-935. <br>' +
        '<br>' +
        'Сивков В.В., Дорохова Е.В., Баширова Л.Д. Контурные течения Северной Атлантики в последнем ледниковом цикле // Океанология. 2015. Т. 55. № 6. С. 994. <br>' +
        '<br>' +
        'Bulycheva E., Kuzmenko I., Sivkov V. Annual sea surface oil pollution of the south-eastern part of the Baltic sea by satellite data for 2006-2013 // Baltica. 2014. V. 27. P. 9-14. <br>' +
        '<br>' +
        'Christiansen C., Kunzendorf H., Emeis K., (…) Sivkov V. Temporal and spatial sedimentation rate variabilities in the eastern Gotland Basin, the Baltic Sea // Boreas. 2002. V. 31 (1). P 65-74. <br>' +
        '<br>' +
        'Dorokhov D.V., Dorokhova E.V., Sivkov V.V. Iceberg and ice-keel ploughmarks on the Gdansk-Gotland Sill (South-Eastern Baltic Sea) // Geo-Marine Letters. 2018. Т. 38. № 1. С. 83-94. <br>' +
        '<br>' +
        'Grigoriev A., Zhamoida V., Spiridonov M., (...), Sivkov V., Ryabchuk D. Late-glacial and Holocene palaeoenvironments in the Baltic sea based on a sedimentary record from the Gdansk Basin // Climate Research. 2011. 48(1). P. 13-21. <br>' +
        '<br>' +
        'Kasiñski J.R., Kramarska R., Slodkowska B., Sivkov V. and Piwocki M. Paleocene and Eocene deposits on the eastern margin of the Gulf of Gdañsk (Yantarny P-1 bore hole, Kaliningrad region, Russia) // Geological Quarterly. 2020. 64 (1). P. 29–53. <br>' +
        '<br>' +
        'Sergeev, A.Y., Zhamoida, V.A., Ryabchuk, D.V., Buynrvich I.V., Sivkov V.V.(...), Bitinas, A., Pupienis, D. Genesis, distribution and dynamics of lagoon marl extrusions along the Curonian Spit, southeast Baltic Coast // Boreas. 2017. V. 46 (1). P. 69-82 <br>' +
        '<br>' +
        'Sivkov V.V., Chubarenko B.V. Influence of amber mining on the concentration and chemical composition of suspended sedimentary matter (Sambian Peninsula, Southeast Baltic) // Marine Georesources and Geotechnology. 1997. 15(2). P. 115-126 <br>' +
        '<br>' +
        'Sivkov, V., Gorbatskiy, V., Kuleshov, A., Zhurov, Y. Muddy contourites in the Baltic Sea: an example of a shallow-water contourite system // Geological Society Memoir. 2002. 22(1). P. 121-136. <br>' +
        '<br>' +
        'Ulyanova M., Sivkov V., Kanapatskij T., Sigalevich P., Pimenov N. Methane fluxes in the southeastern Baltic Sea // Geo-Marine Letters. 2012. V. 32(5-6). P. 535-544. <br>' +
        '<br>' +
        'Virtasalo J.J., Kotilainen A.T., Ryabchuk D., Zhamoida V., Grigoriev A., Sivkov V., Dorokhova E. Middle Holocene to present sedimentary environment in the Easternmost Gulf of Finland (Baltic Sea) and the birth of the Neva River // Marine Geology. 2014. Т. 350. С. 84-96. <br>' +
        '<br>' +
        'Zhamoida V.A., Ryabchuk D.V., Kropatchev Y.P., (...), Boldyrev V.L., Sivkov V.V. Recent sedimentation processes in the coastal zone of the Curonian Spit (Kaliningrad region, Baltic Sea) [Jüngste ablagerungsprozesse im küstengebiet der kurischen nehrung (raum kaliningrad, ostsee)] // Zeitschrift der Deutschen Gesellschaft fur Geowissenschaften. 2009. 160(2). P. 143-157.'},
    {name: 'Ульянова Марина Олеговна', pos: '', img: 'https://firebasestorage.googleapis.com/v0/b/rosgeo-2172c.appspot.com/o/team%2FУльянова_фото.jpg?alt=media&token=0394dd4b-c1d3-404e-9f40-268e50e5a481', text: 'Образование высшее (2003), кандидат наук (2014).<br>' +
        'Член общества с 2003 г. В 2004-2009 гг. работала в КРО ОО РосГео методистом, затем<br>' +
        'директором школы Юного геолога, созданной в 2001 г. при КРО ОО РосГео в рамках<br>' +
        'детско-юношеского геологического движения ОО РосГео. С 2016 по 2021 гг. была<br>' +
        'Председателем КРО ОО РосГео.<br>' +
        'Место работы: Атлантическое отделение Федерального государственного бюджетного<br>' +
        'учреждения науки Института океанологии им. П.П. Ширшова Российской академии наук,<br>' +
        'ведущий научный сотрудник<br>' +
        'Место работы: Балтийский Федеральный университет им. И. Канта, старший научный<br>' +
        'сотрудник, доцент<br>' +
        'В 2014 г. защитила кандидатскую диссертацию на тему «Углеводородные газы в донных<br>' +
        'осадках юго-восточной части Балтийского моря» (кандидат географических наук по<br>' +
        'специальности 25.00.28 – Океанология).<br>' +
        'Научные интересы – морская геология, геохимия, геоэкология, донные осадки,<br>' +
        'углеводородные газы, Балтийское море, морское природопользование, обучение через<br>' +
        'исследование.'},
  ];

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
  }

}

<div class="con">
  <div>
    <h1>Руководство</h1>
    <div>
      <div class="emp_card" *ngFor="let x of members1" (click)="otitle = x.name; otext = x.text; oimg = x.img; o = true;">
        <img
          [src]="x.img">
        <div class="emp_c">
          <a><h3>{{x.name}}</h3></a>
          <span>{{x.pos}}</span>
        </div>
      </div>
      <div style="margin-top: 60px;">
        <h1 routerLink="/news">Члены правления</h1>
        <div>
          <div class="emp_card" *ngFor="let x of members2" (click)="otitle = x.name; otext = x.text; oimg = x.img; o = true;">
            <img
              [src]="x.img">
            <div class="emp_c">
              <a><h3>{{x.name}}</h3></a>
              <span>{{x.pos}}</span>
            </div>
          </div>
        </div>
      </div>
      <!--  <div style="margin-top: 60px;">-->
      <!--    <h1 routerLink="/news">Организации</h1>-->
      <!--    <div>-->
      <!--      <div class="news_card">-->
      <!--        <span>Организация</span>-->
      <!--        <p style="font-weight: 400; margin-top: 10px; width: 100%;">Её описание, роль</p>-->
      <!--      </div>-->
      <!--      <div class="news_card">-->
      <!--        <span>Организация</span>-->
      <!--        <p style="font-weight: 400; margin-top: 10px; width: 100%;">Её описание, роль</p>-->
      <!--      </div>-->
      <!--      <div class="news_card">-->
      <!--        <span>Организация</span>-->
      <!--        <p style="font-weight: 400; margin-top: 10px; width: 100%;">Её описание, роль</p>-->
      <!--      </div>-->
      <!--      <div class="news_card">-->
      <!--        <span>Организация</span>-->
      <!--        <p style="font-weight: 400; margin-top: 10px; width: 100%;">Её описание, роль</p>-->
      <!--      </div>-->
      <!--    </div>-->
    </div>
  </div>
</div>

<div style="cursor: pointer; position: fixed; z-index: 9999999; top: 0; bottom: 0; left: 0; right: 0; background: rgba(0,0,0, 0.1); backdrop-filter: blur(5px);" *ngIf="o" (click)="o = false">
  <svg style="position: fixed; right: 15px; top: 15px; width: 15px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 22.88 22.88" xml:space="preserve">
<path style="fill:#000000;" d="M0.324,1.909c-0.429-0.429-0.429-1.143,0-1.587c0.444-0.429,1.143-0.429,1.587,0l9.523,9.539
	l9.539-9.539c0.429-0.429,1.143-0.429,1.571,0c0.444,0.444,0.444,1.159,0,1.587l-9.523,9.524l9.523,9.539
	c0.444,0.429,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-9.539-9.539l-9.523,9.539c-0.444,0.429-1.143,0.429-1.587,0
	c-0.429-0.444-0.429-1.159,0-1.587l9.523-9.539L0.324,1.909z"/>
  </svg>
</div>
<app-member-popup *ngIf="o" [title]="otitle" [text]="otext" [img]="oimg"></app-member-popup>

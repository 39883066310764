<div class="con" >
<div>
    <h1>Юные геологи</h1>
    <div class="z">
      <p>
        Общественная организация Российское геологическое общество (РОСГЕО) объединяет свыше 2500 индивидуальных членов во многих регионах России, 52 региональных отделений, Межведомственный совет по музейной деятельности, Центральный совет по детско-юношескому геологическому движению.. «Главной целью РОСГЕО является объединение усилий геологов по приумножению минерально-сырьевых ресурсов страны, содействие развитию отечественной науки, защита интересов и прав специалистов. Общество открыто для всех лиц и организаций, готово оказывать постоянную помощь своим членам».
      </p>
    </div>
  </div>
</div>
